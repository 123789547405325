.wrapper {
  border: 2px solid var(--white);
  height: 600px;
  padding: 30px;
  display: grid;
  margin-top: 89px;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.imageContainer {
  display: grid;
  justify-content: center;
  place-items: center;
  align-content: center;
  max-width: 518px;
}

.infoContainer {
  display: grid;
  justify-content: flex-start;
  max-width: 518px;
  max-height: 600px;
  overflow: hidden;
}

.dataTextContainer {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  overflow: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.subtitle {
  margin: 0;
  font-size: 40px;
  font-family: "GT Maru Mega";
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
}

.dataText {
  margin: 0;
  font-family: "GT Maru";
  font-size: 16px;
  font-weight: 500;
}

.dataTextContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.paginatorWrapper {
  display: flex;
  place-items: center;
  place-content: center;
  padding-top: 20px;
}

.paginatorWrapperLink {
  display: flex;
  place-items: center;
  place-content: start;
  padding-top: 20px;
  padding-left: 20px;
}

.loreButtons {
  background: transparent;
  border: 1px solid var(--white);
  color: var(--white);
  border-radius: 25px;
  width: 50px;
  height: 50px;
}
.pageLabel {
  margin: 0;
  font-family: "GT Maru";
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 10px 0 10px;
}
.loreIframe {
  width: 420px;
  height: 420px;
}
.explorer {
  display: none;
}
.author {
  margin: 0;
  font-family: "GT Maru";
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: left;
}

.allAncestry {
  margin: 0;
  font-family: "GT Maru";
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white);
  margin-left: 80px;
}

.paginatorWrapperBottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 30px;
}

.paginatorWrapperLinkMobile {
  display: none;
}

.buttons {
  display: flex;
  justify-content: center;
  place-items: center;
}

.linkAlign {
  display: flex;
  justify-content: start;
  place-items: center;
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .wrapper {
    height: 80vh;
    padding: 0;
    display: grid;
    margin-top: 60x;
  }
  .imageContainer {
    max-width: 100vw;
  }
  .loreIframe {
    width: calc(80vw - 10px);
    height: 360px;
  }
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .infoContainer {
    padding: 0 5px 0 5px;
    max-height: 30vh;
  }

  .explorer {
    display: block;
  }
  .author {
    display: none;
  }
  .paginatorWrapper {
    margin-bottom: 50px;
  }
  .paginatorWrapperLinkMobile {
    place-content: center;
    margin-bottom: 50px;
    padding: 0;
  }
  .paginatorWrapperBottom {
    grid-template-columns: repeat(1, 1fr);
  }
  .paginatorWrapperLinkMobile {
    display: grid;
  }
  .linkAlign {
    display: none;
  }
  .allAncestry {
    margin-left: 0;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .wrapper {
    height: 80vh;
    padding: 0;
    display: grid;
    margin-top: 60x;
  }
  .imageContainer {
    max-width: 100vw;
  }
  .loreIframe {
    width: calc(80vw - 10px);
    height: 360px;
  }
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .infoContainer {
    padding: 0 5px 0 5px;
    max-height: 32vh;
  }
  .explorer {
    display: block;
  }
  .author {
    display: none;
  }
  .paginatorWrapper {
    margin-bottom: 50px;
  }
  .paginatorWrapperLinkMobile {
    place-content: center;
    margin-bottom: 50px;
    padding: 0;
  }
  .paginatorWrapperBottom {
    grid-template-columns: repeat(1, 1fr);
  }
  .paginatorWrapperLinkMobile {
    display: grid;
  }
  .linkAlign {
    display: none;
  }
  .allAncestry {
    margin-left: 0;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  .wrapper {
    height: 80vh;
    padding: 0;
    display: grid;
    margin-top: 60x;
  }
  .imageContainer {
    max-width: 100vw;
  }
  .loreIframe {
    width: calc(80vw - 10px);
    height: 360px;
  }
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
  .infoContainer {
    padding: 0 5px 0 5px;
    max-height: 33vh;
  }
  .explorer {
    display: block;
  }
  .author {
    display: none;
  }
  .paginatorWrapper {
    margin-bottom: 50px;
  }
  .paginatorWrapperLinkMobile {
    place-content: center;
    margin-bottom: 50px;
    padding: 0;
  }
  .paginatorWrapperBottom {
    grid-template-columns: repeat(1, 1fr);
  }
  .paginatorWrapperLinkMobile {
    display: grid;
  }
  .linkAlign {
    display: none;
  }
  .allAncestry {
    margin-left: 0;
  }
}
