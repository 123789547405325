.leaderboardWrapper {
  margin-top: 89px;
  height: 100vh;
  /* background-image: url(../../static/leaderboard-background.jpg); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.leaderboardContainer {
  margin: 0 200px 0 200px;
}

.tableContainer {
  margin: 0 200px 0 200px;
}

.tableDataWrapper {
  width: 100%;
  height: 100%;
}

.tableData {
  width: 100%;
  height: 100%;
  padding-right: 17px;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: stretch;
  padding-left: 10px;
}

.title {
  margin: 10px;
  font-size: 30px;
  font-family: "GT Maru Mega";
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
  text-align: left;
}

.headerElements {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  justify-items: start;
}

.searchInput {
  width: 200px;
  background: transparent;
  border: 0px;
  font-size: 20px;
  color: var(--white);
}

.searchInput:focus {
  outline: none;
}

.searchInput::placeholder {
  color: var(--white);
  opacity: 1;
  text-align: right;
  direction: rtl;
}

.table {
  width: 100%;
  border-collapse: collapse;
  max-width: 100%;
  border: 2px solid white;
  color: var(--white);
  text-decoration: none;
  margin: 0 10px 0 10px;
  font-family: "GT Maru";
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.tdContainer {
  min-height: 15px;
  height: 15px;
  line-height: 15px;
  padding: 0;
}

.table,
td {
  border: 2px solid white;
  color: var(--white);
}

.table tr > td:first-child {
  text-align: center;
  width: 60px;
}
.table tr > td:nth-child(2) {
  width: 300px;
}

.firstPosition {
  background-color: var(--green);
  min-height: 15px;
  height: 15px;
  line-height: 15px;
  padding: 0;
}

.firstPosition > td {
  color: var(--black);
}

.secondThirdPosition {
  background-color: var(--fluoria);
  min-height: 15px;
  height: 15px;
  line-height: 15px;
  padding: 0;
}

.restPositions {
  background-color: var(--black);
  min-height: 15px;
  height: 15px;
  line-height: 15px;
  padding: 0;
}

.timeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.itemsito,
.gradisHead {
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.gradisHead {
  position: absolute;
}

.leaderboardBtn {
  background: var(--white);
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 25px;
}

.gradisLogoContainer {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.gradisLogo {
  width: 150px;
}

.checkmark {
  background: var(--white);
  border-radius: 8px;
}

.holditoContainer {
  position: relative;
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playBtn {
  order-collapse: collapse;
  color: black;
  font-family: "GT Maru";
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  background: var(--white);
  padding: 5px 10px;
  border-radius: 20px;
}

.lastElement {
  width: 78px;
}

.twitterContainer {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .tableDataWrapper {
    width: 340px;
  }
  .table {
    width: 340px;
  }
  .leaderboardContainer {
    margin: 0;
  }

  .tableContainer {
    margin: 0;
  }
  .table {
    font-size: 10px;
  }

  .header {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
    align-content: stretch;
  }

  .table tr > td:first-child {
    width: 30px;
  }

  .table tr > td:nth-child(2) {
    width: 175px;
  }

  .timeContainer {
    width: 92%;
  }

  .title {
    margin: 0 0 0 3px;
  }

  .searchInput::placeholder {
    direction: ltr;
    text-align: left;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .tableDataWrapper {
    width: 360px;
  }
  .table {
    width: 360px;
  }
  .leaderboardContainer {
    margin: 0;
  }

  .tableContainer {
    margin: 0;
  }
  .table {
    font-size: 10px;
  }

  .header {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
    align-content: stretch;
  }

  .table tr > td:first-child {
    width: 30px;
  }

  .table tr > td:nth-child(2) {
    width: 175px;
  }

  .timeContainer {
    width: 92%;
  }

  .title {
    margin: 0 0 0 3px;
  }

  .searchInput::placeholder {
    direction: ltr;
    text-align: left;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  .tableDataWrapper {
    width: 400px;
  }
  .table {
    width: 400px;
  }
  .leaderboardContainer {
    margin: 0;
  }

  .tableContainer {
    margin: 0;
  }
  .table {
    font-size: 12px;
  }

  .header {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: baseline;
    align-content: stretch;
  }

  .table tr > td:first-child {
    width: 30px;
  }

  .table tr > td:nth-child(2) {
    width: 190px;
  }

  .timeContainer {
    width: 92%;
  }

  .title {
    margin: 0 0 0 3px;
  }

  .searchInput::placeholder {
    direction: ltr;
    text-align: left;
  }
}
