.footer {
  background-color: var(--fluoria);
  height: 70px;
  display: grid;
  justify-content: center;
  place-items: center;
}
@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .footer {
    display: none;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .footer {
    display: none;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .footer {
    display: none;
  }
}
