.Logo {
  margin: 0;
  font-size: 60px;
  font-family: "GT Maru Mega";
  font-weight: 500;
  color: var(--white);
  text-decoration: none;
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .Logo {
    font-size: 40px;
    z-index: 2;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .Logo {
    font-size: 40px;
    z-index: 2;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .Logo {
    font-size: 45px;
    z-index: 2;
  }
}
