.wrapper {
  width: 100%;
  height: 60px;
  display: flex;
  place-items: center;
  justify-content: flex-end;
}

.activeStyle {
  color: var(--white);
  text-decoration: none;
  margin: 0 10px 0 10px;
  font-family: "GT Maru";
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.unactiveStyle {
  color: var(--white);
  text-decoration: none;
  margin: 0 10px 0 10px;
  font-family: "GT Maru";
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.unactiveStyle:hover {
  border-bottom: 1px solid var(--white);
}

.gameBtn {
  background: var(--green);
  padding: 10px;
  font-family: "GT Maru";
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 25px;
  color: var(--black);
}

@media (min-width: 1800px) {
  .unactiveStyle,
  .activeStyle {
    font-size: 25px;
  }
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .wrapper {
    display: none;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .wrapper {
    display: none;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  .wrapper {
    display: none;
  }
}
