html,
body {
  margin: 0px;
  height: 100%;
}

.gradisMarquee {
  display: none;
}

.gradisMarquee2 {
  display: none;
}

.mintInfoContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.logo {
  margin: 0;
  position: relative;
  width: 300px;
  height: 190px;
}

.logoStatic,
.logoAnimation {
  position: absolute;
  top: 0;
  width: 300px;
}

.hide {
  display: none;
}

.mintInfo {
  background: rgb(2, 0, 36);
  background: radial-gradient(
    at top,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 46%,
    rgba(142, 191, 255, 1) 100%
  );
  height: 100vh;
  display: grid;
  justify-content: center;
  place-items: center;
}

.mintDates {
  font-family: "GT Maru";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1.5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.gameBtn,
.leaderboardBtn,
.mintInfoBtn {
  background-color: var(--red);
  height: 40px;
  width: 120px;
  text-decoration: none;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  place-items: center;
  font-family: "GT Maru";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -1.5px;
  margin-top: 20px;
  color: var(--white);
}

.gameBtn {
  background-color: var(--fluoria);
  color: var(--white);
  margin-top: 10px;
}

.leaderboardBtn {
  background-color: var(--green);
  color: var(--fluoria);
  margin-top: 10px;
}

.videoHome {
  height: 100vh;
}

.video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
/* .videoMobile { */
/*   display: none; */
/* } */

.container {
  height: 100%;
  display: grid;
}

.imageContainer {
  display: grid;
  place-items: center;
  z-index: 1;
  position: absolute;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.gradisImage {
  width: 300px;
  margin-top: 130px;
}

.downArrow {
  width: 50px;
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 100px, 0);
  }
}

.openseaSection {
  background: url(../../static/gradisBg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  display: grid;
  justify-content: center;
  position: relative;
}

.platformerContainer {
  display: grid;
  justify-content: center;
  place-items: center;
  text-align: center;
  height: 325px;
  margin-top: 80px;
}

.platformerTitle {
  font-family: "GT Maru";
  font-size: 40px;
  font-weight: 500;
  line-height: 30px;
}

.platformerDescription {
  font-family: "GT Maru";
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 100px;
  letter-spacing: -1.5px;
}

.openseaLink {
  background: var(--background);
  color: var(--white);
  height: 64px;
  width: 340px;
  text-decoration: none;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  place-items: center;
  font-family: "GT Maru";
  font-size: 20px;
  font-weight: 500;
}

.openseaLinkMobile {
  display: none;
}

.openseaDisclaimerText {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.disclaimerText {
  font-family: "GT Maru";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -1.5px;
  text-decoration: none;
  color: var(--white);
}
.rarityLink {
  font-family: "GT Maru";
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -1.5px;
  color: var(--white);
}

.disclaimerTextMobile {
  display: none;
}

.convertito {
  position: relative;
  background: var(--fluoria);
  height: 100vh;
}

.convertitoContainer {
  margin-top: 10vh;
}

.convertitoText {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  place-items: center;
  padding-top: 20px;
}

.how {
  font-family: "GT Maru";
  font-size: 40px;
  font-weight: 500;
  position: absolute;
}

.titles1 {
  font-family: "GT Maru";
  font-size: 40px;
  font-weight: 500;
  width: 590px;
}

.underline {
  color: var(--white);
  text-decoration: underline;
  font-size: 16px;
  font-family: "GT Maru";
  font-weight: 500;
  letter-spacing: -1.5px;
}
.underlineGreen {
  color: var(--green);
  text-decoration: underline;
  font-size: 16px;
  font-family: "GT Maru";
  font-weight: 500;
  letter-spacing: -1.5px;
}

.explanation1 {
  margin-top: 30px;
  width: 590px;
  font-size: 16px;
  font-family: "GT Maru";
  font-weight: 500;
  letter-spacing: -1.5px;
}

.explanation1Mobile {
  margin-top: 30px;
  width: 590px;
  font-size: 16px;
  font-family: "GT Maru";
  font-weight: 500;
  letter-spacing: -1.5px;
  display: none;
}

.titles2 {
  font-family: "GT Maru";
  font-size: 40px;
  font-weight: 500;
  width: 590px;
}

.explanation2 {
  margin-top: 30px;
  width: 590px;
  font-size: 16px;
  font-family: "GT Maru";
  font-weight: 500;
  letter-spacing: -1.5px;
}

.explanation2Mobile {
  margin-top: 30px;
  width: 590px;
  font-size: 16px;
  font-family: "GT Maru";
  font-weight: 500;
  letter-spacing: -1.5px;
  display: none;
}

.twoDymentions,
.threeDymentions {
  font-size: 120px;
  font-family: "GT Maru Mega";
  font-weight: 500;
  color: var(--white);
}

.convertitoImage {
  width: 533px;
}

.convertitoImage2 {
  width: 533px;
}

.gradisContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.gradisImages {
  display: flex;
  justify-content: center;
  place-items: center;
  min-height: 80vh;
  flex-direction: column;
}

.cco {
  display: grid;
  justify-content: center;
  place-items: center;
  height: 118vh;
  background: var(--black);
}

.ccoContainer {
  width: 1000px;
}

.ccoTitle {
  font-family: "GT Maru";
  font-size: 40px;
  font-weight: 500;
  color: var(--green);
  text-align: center;
  line-height: 0;
  letter-spacing: -0.92px;
}

.ccoTitleMobile {
  display: none;
}

.cc0 {
  font-family: "GT Maru Mega";
  font-size: 40px;
  font-weight: 500;
  color: var(--green);
  letter-spacing: 0;
}

.ccoDescContainer {
  width: 800px;
  margin: auto;
}

.ccoDescription {
  font-family: "GT Maru";
  font-size: 17px;
  font-weight: 500;
  color: var(--green);
  text-align: center;
}

.ccoLink {
  background: var(--green);
  color: var(--black);
  text-align: center;
  padding: 16px;
  border-radius: 25px;
  width: 330px;
  text-decoration: none;
  font-family: "GT Maru";
}

.ccoLinkContainer {
  display: none;
}

.cc0Image {
  display: none;
}

.ccoIframeContainer {
  width: 600px;
  height: 600px;
}

.lore {
  position: relative;
  display: grid;
  justify-content: center;
  place-items: center;
  background: url(../../static/lore.png);
  display: grid;
  justify-content: center;
  place-items: center;
  height: 100vh;
  background-size: cover;
}

.loreContainter {
  max-width: 1400px;
}

.loreButton {
  position: absolute;
  display: grid;
  place-items: center;
  text-align: center;
  margin-top: 200px;
}

.explanation3 {
  margin-bottom: 30px;
  width: 630px;
  font-size: 19px;
  font-family: "GT Maru";
  font-weight: 500;
}

.loreSelect {
  display: flex;
  width: 330px;
  appearance: none;
  background-color: transparent;

  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 15px;

  border: 1px solid var(--white);
  border-radius: 25px;
  padding: 16px;
  margin: 0;
  font-family: "GT Maru";
  color: #8f8f8f;
  background: var(--black);
  line-height: inherit;
  text-align: center;
}

.gradisGods {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 0px;
  grid-template-rows: 50vw 50vw;
}

.gradisItoImage {
  width: 100%;
  height: 50vw;
}

.gradisItoAnimation {
  opacity: 0;
  width: calc(50vw - ((100vw - 100%) / 2));
  position: absolute;
  z-index: 1;
}

.gradisItoAnimation:hover {
  display: block;
  opacity: 1;
}

/* .gradisIsWholeContainer, */
/* .isImageDesktop { */
/*   margin-top: -125px; */
/* } */

.gradisIsImage {
  width: 100%;
  height: 50vw;
}

.gradisIsAnimation {
  opacity: 0;
  width: calc(50vw - ((100vw - 100%) / 2));
  position: absolute;
  z-index: 1;
}

.gradisIsAnimation:hover {
  display: block;
  opacity: 1;
}

.gradisItoCreationContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  place-content: center;
  place-items: center;
  background-color: var(--ito);
  font-family: GT Maru;
  font-size: 16px;
  font-weight: 700;
  height: 50vw;
}
.gradisItoCreationContainerMobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  place-content: center;
  place-items: center;
  background-color: var(--ito);
  font-family: GT Maru;
  font-size: 16px;
  font-weight: 700;
  height: 100vh;
  display: none;
}

.gradisIsEndContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  place-content: center;
  place-items: center;
  background-color: var(--is);
  font-family: GT Maru;
  font-size: 16px;
  font-weight: 700;
  height: 50vw;
}

.isContainerMobile {
  display: none;
}

.isImageMobile {
  display: none;
}

.footer {
  height: 100vh;
  display: grid;
  place-items: center;
  background: var(--black);
}
.footerText {
  width: 100%;
  text-align: center;
}

.footerTitle {
  font-family: "GT Maru";
  font-size: 40px;
  font-weight: 500;
  letter-spacing: -0.92px;
  line-height: 0;
}

.footerDescription {
  font-family: GT Maru;
  font-size: 16px;
  font-weight: 700;
}

.footerDescriptionMobile {
  display: none;
}

.footerContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  padding-top: 100px;
  text-align: center;
  grid-column-gap: 100px;
}

.footerButton {
  display: block;
  width: 330px;
  border: 1px solid var(--white);
  border-radius: 25px;
  padding: 16px;
  margin: 0;
  font-family: "GT Maru";
  color: var(--white);
  text-decoration: none;
  text-align: center;
}

.footerImageContainer {
  display: grid;
  place-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;
  padding-bottom: 100px;
}

.twoD,
.threeD {
  width: 500px;
}

@media (min-width: 1800px) {
  .gradisImage {
    width: 452px;
  }
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .gradisMarquee {
    display: block;
    position: absolute;
    margin-top: 70vh;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
  }

  .gradisMarquee2 {
    display: block;
    position: absolute;
    margin-top: 80vh;
    overflow: hidden;
    white-space: nowrap;
    width: 320px;
    justify-content: space-between;
  }

  .gradisText {
    font-size: 40px;
    font-family: "GT Maru Mega";
    font-weight: 500;
    color: var(--white);
  }

  .gameText {
    font-family: "GT Maru";
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0px;
  }

  .videoHome {
    width: 100vw;
  }

  .gradisImage {
    display: none;
  }

  .openseaSection {
    background: url(../../static/gradisBgMobile.png);
    width: 100vw;
    height: 120vh5;
  }

  .gradisGods {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
    grid-template-rows: repeat(4, 50vh);
  }
  .gradisItoImage {
    width: 100vw;
    height: 50vh;
  }
  .gradisItoCreationContainer {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;
    display: none;
  }
  .gradisItoCreationContainerMobile {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 50vh;
    display: grid;
    justify-content: center;
    place-items: center;
  }

  .itoContainerMobile {
    width: 260px;
    display: flex;
    flex-direction: column;
    place-content: center;
  }

  .itoCreationImage {
    width: 150px;
  }

  .itoContainer {
    width: 100%;
    text-align: center;
  }

  .gradisIsEndContainer {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 50vh;
  }
  .isContainer {
    display: none;
  }

  .isContainerMobile {
    display: block;
    width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-content: center;
  }
  .isEndImageContainer {
    width: 100%;
    text-align: center;
  }

  .isEndImage {
    width: 150px;
  }

  .isImageMobile {
    display: block;
  }

  .isImageDesktop {
    display: none;
  }

  .gradisIsImage {
    width: 100vw;
    height: 50vh;
  }

  .platformerContainer {
    display: grid;
    justify-content: center;
    place-items: center;
    text-align: center;
    height: 100vh;
    padding-left: 16px;
    padding-right: 16px;
  }

  .platformerTitle {
    font-size: 25px;
    line-height: 0px;
  }

  .platformerDescription {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .openseaIcon {
    width: 30px;
  }

  .openseaLink {
    display: none;
  }

  .openseaLinkContainer {
    display: flex;
    justify-content: center;
  }

  .openseaLinkMobile {
    background: var(--background);
    color: var(--white);
    text-decoration: none;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    place-items: center;
    font-family: "GT Maru";
    font-size: 20px;
    font-weight: 500;
    margin-top: 40px;
    height: 40px;
    width: 230px;
    border-radius: 30px;
    font-size: 15px;
  }
  .disclaimerText {
    display: none;
  }

  .disclaimerTextMobile {
    display: block;
    font-family: "GT Maru";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -1.5px;
  }

  .ccoContainer {
    width: 100%;
  }

  .ccoTitle {
    display: none;
  }

  .ccoTitleMobile {
    display: block;
    font-family: "GT Maru";
    font-size: 20px;
    font-weight: 500;
    color: var(--green);
    text-align: center;
    line-height: normal;
  }

  .ccoDescContainer {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .ccoDescription {
    font-size: 14px;
  }

  .ccoIframeContainer {
    width: 100%;
    height: 50vh;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 120px;
  }

  .ccoLink {
    height: 15px;
    width: 230px;
    font-size: 12px;
    display: none;
  }

  .ccoLinkWhite {
    background: var(--white);
    color: var(--black);
    text-align: center;
    padding: 16px;
    border-radius: 25px;
    text-decoration: none;
    font-family: "GT Maru";
    height: 15px;
    width: 230px;
    font-size: 12px;
  }

  .ccoLinkContainer {
    width: 100vw;
    display: grid;
    justify-content: center;
    place-items: center;
    height: 120px;
    background: var(--fluorium);
    padding-top: 50px;
    padding-bottom: 90px;
  }

  .cube {
    width: 50px;
  }

  .convertito {
    height: 100%;
    margin-bottom: 50px;
  }

  .gradisContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .gradisImages {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    justify-content: center;
    place-items: center;
  }

  .convertitoImage {
    width: 250px;
    margin-top: 200px;
  }
  .convertitoImage2 {
    width: 250px;
  }

  .titles1,
  .titles2 {
    font-size: 30px;
    text-align: center;
    width: 288px;
  }

  .explanation1,
  .explanation2 {
    font-size: 12px;
    width: 288px;
  }

  .explanation1,
  .explanation2 {
    display: none;
  }

  .explanation1Mobile {
    display: block;
    font-size: 12px;
    width: 288px;
  }
  .explanation2Mobile {
    display: block;
    margin-top: -70px;
    font-size: 12px;
    width: 288px;
  }

  .lore {
    width: 100%;
    background: url(../../static/loreMobile.png);
    display: grid;
    justify-content: center;
    place-items: center;
    height: 105vh;
    background-size: cover;
  }

  .explanation3 {
    font-size: 10px;
    width: 288px;
  }

  .loreButton {
    margin-top: -10vh;
  }
  .loreSelect {
    width: 250px;
    padding: 12px;
  }

  .footer {
    width: 288px;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .footerContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .footerText {
    margin-top: 100px;
  }

  .footerTitle {
    font-size: 30px;
    width: 288px;
  }

  .footerDescription {
    font-size: 12px;
    width: 288px;
    display: none;
  }

  .footerDescriptionMobile {
    display: block;
    font-size: 8px;
    font-family: GT Maru;
    font-weight: 700;
  }

  .footerButton {
    width: 250px;
    padding: 12px;
  }

  .threeD,
  .twoD {
    width: 250px;
  }
  .logo {
    width: 200px;
    height: 130px;
  }

  .logoStatic,
  .logoAnimation {
    width: 200px;
  }

  .mintDates {
    font-size: 16px;
  }

  .mintInfoBtn {
    margin-top: 20px;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .gradisMarquee {
    display: block;
    position: absolute;
    margin-top: 74vh;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
  }

  .gradisMarquee2 {
    display: block;
    position: absolute;
    margin-top: 84vh;
    overflow: hidden;
    white-space: nowrap;
    width: 375px;
    justify-content: space-between;
  }

  .gradisText {
    font-size: 40px;
    font-family: "GT Maru Mega";
    font-weight: 500;
    color: var(--white);
  }

  .gameText {
    font-family: "GT Maru";
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0px;
  }

  .videoHome {
    width: 100vw;
  }

  .gradisImage {
    display: none;
  }

  .openseaSection {
    background: url(../../static/gradisBgMobile.png);
    width: 100vw;
    height: 105vh;
  }

  .platformerText {
    margin-bottom: 0;
    margin-top: -100px;
  }

  .gradisGods {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
    grid-template-rows: repeat(4, 50vh);
  }
  .gradisItoImage {
    width: 100vw;
    height: 50vh;
  }
  .gradisItoCreationContainer {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;
    display: none;
  }
  .gradisItoCreationContainerMobile {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 50vh;
    display: grid;
    justify-content: center;
    place-items: center;
  }

  .itoContainerMobile {
    width: 260px;
    display: flex;
    flex-direction: column;
    place-content: center;
  }

  .itoCreationImage {
    width: 150px;
  }

  .itoContainer {
    width: 100%;
    text-align: center;
  }

  .gradisIsEndContainer {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 50vh;
  }
  .isContainer {
    display: none;
  }

  .isContainerMobile {
    display: block;
    width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-content: center;
  }
  .isEndImageContainer {
    width: 100%;
    text-align: center;
  }

  .isEndImage {
    width: 150px;
  }

  .gradisIsImage {
    width: 100vw;
    height: 50vh;
  }
  .isImageMobile {
    display: block;
  }

  .isImageDesktop {
    display: none;
  }

  .platformerContainer {
    display: grid;
    justify-content: center;
    place-items: center;
    text-align: center;
    height: 120vh;
  }

  .platformerTitle {
    font-size: 25px;
    line-height: 30px;
  }

  .platformerDescription {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .openseaIcon {
    width: 30px;
  }

  .openseaLink {
    display: none;
  }

  .openseaLinkContainer {
    display: flex;
    justify-content: center;
  }

  .openseaLinkMobile {
    background: var(--background);
    color: var(--white);
    text-decoration: none;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    place-items: center;
    font-family: "GT Maru";
    font-size: 20px;
    font-weight: 500;
    margin-top: 40px;
    height: 40px;
    width: 230px;
    border-radius: 30px;
    font-size: 15px;
  }
  .disclaimerText {
    display: none;
  }

  .disclaimerTextMobile {
    display: block;
    font-family: "GT Maru";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -1.5px;
  }
  /* aqui lo otro */
  .cco {
    height: 130vh;
  }

  .ccoContainer {
    width: 100%;
  }

  .ccoTitle {
    display: none;
  }

  .ccoTitleMobile {
    display: block;
    font-family: "GT Maru";
    font-size: 20px;
    font-weight: 500;
    color: var(--green);
    text-align: center;
    line-height: normal;
  }

  .ccoDescContainer {
    width: 343px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .ccoDescription {
    font-size: 14px;
    width: 343px;
  }

  .ccoIframeContainer {
    width: 343px;
    height: 50vh;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 120px;
  }

  .ccoLink {
    height: 15px;
    width: 230px;
    font-size: 12px;
    display: none;
  }

  .ccoLinkWhite {
    background: var(--white);
    color: var(--black);
    text-align: center;
    padding: 16px;
    border-radius: 25px;
    text-decoration: none;
    font-family: "GT Maru";
    height: 15px;
    width: 230px;
    font-size: 12px;
  }

  .ccoLinkContainer {
    width: 100vw;
    display: grid;
    justify-content: center;
    place-items: center;
    height: 120px;
    background: var(--fluorium);
    padding-top: 50px;
    padding-bottom: 90px;
  }

  .cube {
    width: 50px;
  }

  .convertito {
    height: 100%;
    margin-bottom: 50px;
  }

  .gradisContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .gradisImages {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    justify-content: center;
    place-items: center;
  }

  .convertitoImage {
    width: 250px;
    margin-top: 200px;
  }
  .convertitoImage2 {
    width: 250px;
  }

  .titles1,
  .titles2 {
    font-size: 30px;
    text-align: center;
    width: 343px;
  }

  .explanation1,
  .explanation2 {
    font-size: 12px;
    width: 343px;
  }
  .explanation2 {
    display: none;
  }

  .explanation2Mobile {
    display: block;
    margin-top: -70px;
    font-size: 12px;
    width: 343px;
  }

  .lore {
    width: 100%;
    height: 95vh;
    background: url(../../static/loreMobile.png);
    display: grid;
    justify-content: center;
    place-items: center;
    height: 105vh;
    background-size: cover;
  }

  .explanation3 {
    font-size: 10px;
    width: 343px;
  }

  .loreButton {
    margin-top: 3vh;
  }
  .loreSelect {
    width: 250px;
    padding: 12px;
  }

  .footer {
    width: 375px;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .footerContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .footerText {
    margin-top: 100px;
  }
  .footerTitle {
    font-size: 30px;
    width: 343px;
  }

  .footerDescription {
    font-size: 12px;
    width: 343px;
    display: none;
  }

  .footerDescriptionMobile {
    display: block;
    font-size: 8px;
    font-family: GT Maru;
    font-weight: 700;
  }

  .footerButton {
    width: 343px;
    padding: 12px;
  }

  .threeD,
  .twoD {
    width: 250px;
  }

  .logo {
    width: 270px;
    height: 170px;
  }

  .logoStatic,
  .logoAnimation {
    width: 270px;
  }

  .mintDates {
    font-size: 16px;
  }

  .mintInfoBtn {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  .gradisMarquee {
    display: block;
    position: absolute;
    margin-top: 76vh;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
  }

  .gradisMarquee2 {
    display: block;
    position: absolute;
    margin-top: 86vh;
    overflow: hidden;
    white-space: nowrap;
    width: 375px;
    justify-content: space-between;
  }

  .gradisText {
    font-size: 40px;
    font-family: "GT Maru Mega";
    font-weight: 500;
    color: var(--white);
  }

  .gameText {
    font-family: "GT Maru";
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0px;
  }

  .videoHome {
    width: 100vw;
  }

  .gradisImage {
    display: none;
  }

  .openseaSection {
    background: url(../../static/gradisBgMobile.png);
    width: 100vw;
    height: 102vh;
  }

  .platformerText {
    margin-bottom: 200px;
    margin-top: -100px;
  }

  .gradisGods {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
    grid-template-rows: repeat(4, 50vh);
  }
  .gradisItoImage {
    width: 100vw;
    height: 50vh;
  }
  .gradisItoCreationContainer {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;
    display: none;
  }
  .gradisItoCreationContainerMobile {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 50vh;
    display: grid;
    justify-content: center;
    place-items: center;
  }

  .itoContainerMobile {
    width: 260px;
    display: flex;
    flex-direction: column;
    place-content: center;
  }

  .itoCreationImage {
    width: 150px;
  }

  .itoContainer {
    width: 100%;
    text-align: center;
  }

  .gradisIsEndContainer {
    width: calc(100vw - 32px);
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
    height: 50vh;
  }

  .isContainer {
    display: none;
  }

  .isContainerMobile {
    display: block;
    width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    place-content: center;
  }
  .isEndImageContainer {
    width: 100%;
    text-align: center;
  }

  .isEndImage {
    width: 150px;
  }

  .gradisIsImage {
    width: 100vw;
    height: 50vh;
  }

  .isImageMobile {
    display: block;
  }

  .isImageDesktop {
    display: none;
  }

  .platformerContainer {
    display: grid;
    justify-content: center;
    place-items: center;
    text-align: center;
    height: 110vh;
    padding-left: 16px;
    padding-right: 16px;
  }

  .platformerTitle {
    font-size: 25px;
    line-height: 30px;
  }

  .platformerDescription {
    font-size: 10px;
    margin-bottom: 0px;
  }

  .openseaIcon {
    width: 30px;
  }

  .openseaLink {
    display: none;
  }

  .openseaLinkContainer {
    display: flex;
    justify-content: center;
  }

  .openseaLinkMobile {
    background: var(--background);
    color: var(--white);
    text-decoration: none;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    place-items: center;
    font-family: "GT Maru";
    font-size: 20px;
    font-weight: 500;
    margin-top: 40px;
    height: 40px;
    width: 230px;
    border-radius: 30px;
    font-size: 15px;
  }
  .disclaimerText {
    display: none;
  }

  .disclaimerTextMobile {
    display: block;
    font-family: "GT Maru";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -1.5px;
  }
  .cco {
    height: 130vh;
  }

  .ccoContainer {
    width: 100%;
  }

  .ccoTitle {
    display: none;
  }

  .ccoTitleMobile {
    display: block;
    font-family: "GT Maru";
    font-size: 20px;
    font-weight: 500;
    color: var(--green);
    text-align: center;
    line-height: normal;
  }

  .ccoDescContainer {
    width: 343px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .ccoDescription {
    font-size: 14px;
    width: 343px;
  }

  .ccoIframeContainer {
    width: 343px;
    height: 50vh;
    margin-bottom: 120px;
  }

  .ccoLink {
    height: 15px;
    width: 230px;
    font-size: 12px;
    display: none;
  }
  .ccoLinkWhite {
    background: var(--white);
    color: var(--black);
    text-align: center;
    padding: 16px;
    border-radius: 25px;
    text-decoration: none;
    font-family: "GT Maru";
    height: 15px;
    width: 230px;
    font-size: 12px;
  }

  .ccoLinkContainer {
    width: 100vw;
    display: grid;
    justify-content: center;
    place-items: center;
    height: 120px;
    background: var(--fluorium);
    padding-top: 50px;
    padding-bottom: 70px;
  }

  .cube {
    width: 50px;
  }

  .convertito {
    height: 100%;
    margin-bottom: 50px;
  }

  .gradisContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .gradisImages {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    justify-content: center;
    place-items: center;
  }

  .convertitoImage {
    width: 250px;
    margin-top: 200px;
  }

  .convertitoImage2 {
    width: 250px;
  }

  .titles1,
  .titles2 {
    font-size: 30px;
    text-align: center;
    width: 343px;
  }

  .explanation1,
  .explanation2 {
    font-size: 14px;
    width: 343px;
  }

  .explanation2 {
    display: none;
  }

  .explanation2Mobile {
    display: block;
    margin-top: -70px;
    font-size: 14px;
    width: 343px;
  }

  .lore {
    width: 100%;
    background: url(../../static/loreMobile.png);
    display: grid;
    justify-content: center;
    place-items: center;
    height: 105vh;
    background-size: cover;
  }

  .explanation3 {
    font-size: 10px;
    width: 343px;
  }
  .loreButton {
    margin-top: 4vh;
  }
  .loreSelect {
    width: 250px;
    padding: 12px;
  }

  .footer {
    height: 100%;
    width: 100vw;
  }

  .footerContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .footerText {
    margin-top: 100px;
  }

  .footerTitle {
    font-size: 30px;
    width: 100%;
  }

  .footerDescription {
    font-size: 12px;
    width: 343px;
    display: none;
  }

  .footerDescriptionMobile {
    display: block;
    font-size: 8px;
    font-family: GT Maru;
    font-weight: 700;
  }

  .footerButton {
    width: 343px;
    padding: 12px;
  }

  .threeD,
  .twoD {
    width: 250px;
  }

  .logo {
    width: 280px;
    height: 184px;
  }

  .logoStatic,
  .logoAnimation {
    width: 280px;
  }

  .mintDates {
    font-size: 16px;
  }

  .mintInfoBtn {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1201px) {
}
