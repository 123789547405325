.wrapper {
  width: 100%;
  height: calc(100vh - 89px);
  position: fixed;
  top: 89px;
  background: var(--black);
  padding: 100px;
}

.closeBtn {
  color: var(--green);
  float: left;
  font-size: 40px;
  font-weight: bold;
  background-color: var(--black);
  border: 0px;
  display: flex;
  width: 100%;
  cursor: pointer;
  position: absolute;
  z-index: 2;
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .wrapper {
    height: calc(100vh - 60px);
    top: 60px;
    padding: 5px;
    width: 100%;
    overflow-y: auto;
  }
}

@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .wrapper {
    height: calc(100vh - 60px);
    top: 60px;
    padding: 5px;
    width: 100%;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .wrapper {
    height: calc(100vh - 60px);
    top: 60px;
    padding: 10px;
    width: 100%;
    overflow-y: auto;
  }
}
