.rarityContainer {
  margin-top: 89px;
  height: calc(100% - 89px);
  background: var(--black);
}

.title {
  width: 100%;
  margin: 0;
  font-family: "GT Maru Mega";
  font-size: 200px;
  color: var(--fluorium);
  font-weight: 500;
  text-align: center;
}

.rarityWrapper {
  display: grid;
  grid-template-columns: minmax(300px, 20%) 80%;
}

.currentPage {
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
}

.pageLabel,
.rankingLabel {
  line-height: 33px;
  font-size: 18px;
  font-family: "GT Maru";
  color: var(--fluorium);
  margin: 0 0 0 10px;
}

.pageLabel {
  margin: 0 10px 0 0;
}

.gradisImg {
  width: 200px;
  height: 200px;
  margin: 0 10px 0 10px;
  border-radius: 8px;
}

.paginator {
  max-width: 1100px;
  display: flex;
  place-items: center;
  place-content: center;
  padding: 20px 0 20px 0;
}

.rarityButtons {
  width: 50px;
  background-color: var(--light-violet);
  margin-right: 10px;
  border-radius: 25px;
  height: 50px;
  border: 0;
}

.filterTitle {
  margin-left: 10px;
  margin-right: 10px;
  color: var(--green);
  line-height: 33px;
  font-size: 18px;
  font-family: "GT Maru";
}

.filterButton {
  min-width: 43px !important;
  background-color: var(--black);
  border: 1px solid var(--black);
  margin-right: 10px;
  color: var(--light-violet);
  margin-top: 10px;
  border-radius: 5px;
  line-height: 33px;
  font-size: 18px;
  font-family: "GT Maru";
}

.filterInput {
  width: 100%;
  padding: 12px 20px;
  margin-top: 8px;
  display: inline-block;
  background-color: var(--blue);
  border: 1px solid var(--green);
  color: var(--green);
  border: 1px solid var(--violet);
  box-sizing: border-box;
  border-radius: 5px;
}

.collapsible {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border-radius: 0;
  margin-top: 15px;
  background-color: var(--green);
  color: var(--blue);
}

.content {
  padding: 0 18px;
  display: none;
  overflow-y: scroll;
  border: 1px solid var(--green);
  max-height: 200px;
}

.checkboxContainer {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  font-family: "GT Maru";
  font-size: 20px;
  font-weight: 500;
  color: var(--green);
  line-height: 25px;
}
.checkboxContainer:hover {
  background-color: var(--green);
  color: var(--blue);
}

.rarityModalWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
}

.iframeContainer {
  position: relative;
  overflow: hidden;
  max-width: 500px;
  min-height: 550px;
  width: 100%;
  height: 100%;
}

.rankingLabel {
  margin-top: 50px;
  font-family: "GT Maru";
  font-size: 20px;
  font-weight: 500;
  color: var(--green);
  line-height: 25px;
}

.rankingLabelIframe {
  margin-top: 50px;
  font-family: "GT Maru";
  font-size: 20px;
  font-weight: 500;
  color: var(--green);
  line-height: 25px;
  float: right;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  max-width: 400px;
}

td,
th {
  border: 1px solid var(--green);
  text-align: left;
  padding: 8px;
  color: var(--green);
}

.rarityTable tr:hover td {
  background-color: var(--green);
  color: var(--blue);
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.modalLink {
  min-width: 43px !important;
  background-color: var(--black);
  border: 1px solid var(--black);
  margin-right: 10px;
  color: var(--light-violet);
  margin-top: 10px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 20px;
  font-family: "GT Maru";
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .rarityWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .currentPage {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .title {
    font-size: 140px;
  }

  .rarityModalWrapper {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 50px;
  }

  .iframeContainer {
    max-width: calc(100% - 25px);
    min-height: 550px;
  }
}

@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .rarityWrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .currentPage {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .gradisImg {
    width: 95vw;
    height: 95vw;
  }

  .title {
    font-size: 140px;
  }

  .rarityModalWrapper {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 50px;
  }

  .iframeContainer {
    max-width: calc(100% - 10px);
    min-height: 550px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 500px) {
  .rarityWrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .currentPage {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .gradisImg {
    width: 95vw;
    height: 95vw;
  }

  .title {
    font-size: 140px;
  }
  .rarityModalWrapper {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 50px;
  }

  .iframeContainer {
    max-width: calc(100% - 15px);
    min-height: 550px;
  }
}
