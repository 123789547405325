@import url("./fonts.css");

:root {
  --green: #b4ed00;
  --blue: #000a2b;
  --violet: #7b8dff;
  --light-violet: #512bf4;
  --red: #e64600;
  --dark-blue: #000a2b;
  --white: #ffffff;
  --black: #000000;
  --fluorium: rgb(193, 235, 56);
  --fluoria: rgb(73, 56, 235);
  --background: #4938eb;
  --ito: #00d4ef;
  --is: #fecb6e;
  --light-yellow: #fffd9c;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--fluoria);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
  scroll-behavior: smooth;
}

.lore-iframe {
  width: 420px;
  height: 420px;
}

.responsiveIframe {
  width: 100%;
  height: 100%;
}
.phase1 {
  margin-top: -150px;
  height: calc(100vh + 150px);

  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 10, 43, 1) 37%,
    rgba(66, 5, 109, 1) 46%,
    rgba(132, 0, 174, 1) 52%,
    rgba(222, 90, 194, 1) 65%,
    rgba(0, 252, 136, 1) 70%,
    rgba(73, 55, 235, 1) 84%,
    rgba(73, 55, 235, 1) 100%
  );
}

.phase2 {
  margin-top: -150px;
  height: calc(100vh + 150px);

  background: radial-gradient(
    circle,
    rgba(53, 0, 0, 1) 0%,
    rgba(53, 0, 0, 1) 37%,
    rgba(255, 0, 0, 1) 55%,
    rgba(253, 255, 55, 1) 63%,
    rgba(255, 161, 205, 1) 68%,
    rgba(222, 90, 194, 1) 72%,
    rgba(255, 0, 0, 1) 84%
  );
}

.phase3 {
  margin-top: -150px;
  height: calc(100vh + 150px);

  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 10, 43, 1) 37%,
    rgba(81, 43, 244, 1) 65%,
    rgba(0, 252, 136, 1) 70%,
    rgba(180, 237, 0, 1) 84%
  );
}
