.NavBar {
  width: 100%;
  height: 89px;
  z-index: 1;
  background-color: var(--fluoria);
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 2;
}

.NavBarContainer {
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  place-items: center;
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .NavBar {
    position: fixed;
    height: 60px;
    width: 320px;
  }

  .NavBarContainer {
    width: 320px;
    display: grid;
    justify-content: center;
    place-items: center;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .NavBar {
    position: fixed;
    height: 60px;
    width: 100vw;
  }

  .NavBarContainer {
    width: 100%;
    display: grid;
    justify-content: center;
    place-items: center;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  .NavBar {
    position: fixed;
    height: 60px;
    width: 100vw;
  }

  .NavBarContainer {
    width: 100%;
    display: grid;
    justify-content: center;
    place-items: center;
  }
}
