@font-face {
  font-family: 'GT Maru';
  src: url('./static/GT-Maru-Mono.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Maru-Mega-Mini';
  src: url('./static/GT-Maru-Proportional.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'GT Maru Mega';
  src: url('./static/GT-Maru-Mega-Maxi.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
}
