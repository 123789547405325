.LoresWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  margin-top: 10px;
}

.LoresContainer {
  width: 1100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
}

.heads {
  width: 100px;
  height: 100px;
}

