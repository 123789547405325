.mintInfoContainer {
  margin-top: 89px;
  position: relative;
  background: url(../../static/faq-background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  margin: 0;
  font-size: 80px;
  font-family: "GT Maru Mega";
  font-weight: 500;
  color: var(--light-yellow);
  text-decoration: none;
  text-align: center;
}

.questionsWrapper {
  z-index: 1;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 89px);
}

.questions {
  margin: 100px 200px 0 200px;
  width: 800px;
  height: 60vh;
  overflow-y: scroll;
  padding-right: 17px;
  width: 100%;
}

.question,
.answer {
  width: 83%;
  color: var(--light-yellow);
  text-decoration: none;
  margin: 0 10px 0 10px;
  font-family: "GT Maru";
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.question {
  font-size: 18px;
  font-weight: 600;
}

.answer {
  margin-bottom: 30px;
}

.twitterLink {
  color: var(--white);
  text-decoration: none;
  font-weigh: 600;
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .mintInfoContainer {
    margin-top: 60px;
    height: 100%;
  }

  .questionsWrapper {
    width: 100vw;
  }

  .questions {
    margin: 50px 0 0 0;
    width: 100vw;
    height: 100%;
    overflow: unset;
    padding-left: 10px;
  }

  .questionsData {
    width: 97vw;
    height: 100%;
    overflow-y: scroll;
    padding-right: 0;
  }

  .question,
  answer {
    width: 100%;
    margin: 0;
  }

  .radiant {
    display: none;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .mintInfoContainer {
    margin-top: 60px;
    height: 100%;
  }

  .questionsWrapper {
    width: 100vw;
  }

  .questions {
    margin: 50px 0 0 0;
    width: 100vw;
    height: 100%;
    overflow: unset;
    padding-left: 10px;
  }

  .questionsData {
    width: 97vw;
    height: 100%;
    overflow-y: scroll;
    padding-right: 0;
  }

  .question,
  answer {
    width: 100%;
    margin: 0;
  }

  .radiant {
    display: none;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  .mintInfoContainer {
    margin-top: 60px;
    height: 100%;
  }
  .questionsWrapper {
    width: 100vw;
  }

  .questions {
    margin: 50px 0 0 0;
    width: 100vw;
    height: 100%;
    overflow: unset;
    padding-left: 10px;
  }

  .questionsData {
    width: 97vw;
    height: 100%;
    overflow-y: scroll;
    padding-right: 0;
  }

  .question,
  answer {
    width: 100%;
    margin: 0;
  }

  .radiant {
    display: none;
  }
}
