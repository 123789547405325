.hamburgerContainer {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-width: 374px) {
  .hamburgerContainer {
    width: calc(100vw - 72px);
    position: absolute;
    justify-content: flex-end;
    display: flex;
  }
  .menuButton {
    background: transparent;
    border: 0;
  }

  .menuMobile {
    margin-top: 60px;
    background: var(--fluoria);
    height: calc(100vh - 60px);
    position: fixed;
    width: 100%;
    z-index: 2;
  }
  .activeStyle {
    color: var(--white);
    text-decoration: none;
    margin: 0 10px 0 10px;
    font-family: "GT Maru";
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }

  .unactiveStyle {
    color: var(--white);
    text-decoration: none;
    margin: 0 10px 0 10px;
    font-family: "GT Maru";
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
  }

  .linksContainer {
    height: 300px;
    display: grid;
    margin-top: 100px;
    text-align: center;
  }
}
@media only screen and (min-device-width: 375px) and (max-width: 413px) {
  .hamburgerContainer {
    width: calc(100vw - 72px);
    position: absolute;
    justify-content: flex-end;
    display: flex;
  }
  .menuButton {
    background: transparent;
    border: 0;
    margin-top: 15px;
    margin-bottom: auto;
  }

  .menuMobile {
    margin-top: 60px;
    background: var(--fluoria);
    height: calc(100vh - 60px);
    position: fixed;
    width: 100%;
    z-index: 2;
  }
  .activeStyle {
    color: var(--white);
    text-decoration: none;
    margin: 0 10px 0 10px;
    font-family: "GT Maru";
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }

  .unactiveStyle {
    color: var(--white);
    text-decoration: none;
    margin: 0 10px 0 10px;
    font-family: "GT Maru";
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
  }

  .linksContainer {
    height: 300px;
    display: grid;
    margin-top: 100px;
    text-align: center;
  }
}
@media only screen and (min-width: 414px) and (max-width: 500px) {
  .hamburgerContainer {
    width: calc(100vw - 72px);
    position: absolute;
    justify-content: flex-end;
    display: flex;
  }

  .menuButton {
    background: transparent;
    border: 0;
    margin-top: 15px;
    margin-bottom: auto;
  }

  .menuMobile {
    margin-top: 60px;
    background: var(--fluoria);
    height: calc(100vh - 60px);
    position: fixed;
    width: 100%;
    z-index: 2;
  }
  .activeStyle {
    color: var(--white);
    text-decoration: none;
    margin: 0 10px 0 10px;
    font-family: "GT Maru";
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }

  .unactiveStyle {
    color: var(--white);
    text-decoration: none;
    margin: 0 10px 0 10px;
    font-family: "GT Maru";
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
  }

  .linksContainer {
    height: 300px;
    display: grid;
    margin-top: 100px;
    text-align: center;
  }
}
